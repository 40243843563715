/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

var monthNames = [ "január", "február", "március", "április", "május", "június", "július", "augusztus", "szeptember", "október", "november", "december"];

//var soldRoom = ["6","13","15","25","27","29","30","32","33","34","49","50","52","53"];

var villaFloorDB = {
  'floors': {
    0: {
      'name': 'Földszint',
      'click-areas': [
        {
          'points': "231,59,262,63,261,69,282,71,276,133,221,128",
          'room_no': "1"
        },
        {
          'points': "282,71,303,73,303,66,335,69,328,139,276,133",
          'room_no': "2"
        },
        {
          'points': "221,151,274,156,267,220,246,217,246,223,216,220",
          'room_no': "3"
        },
        {
          'points': "183,150,183,204,130,204,130,220,103,220,103,149",
          'room_no': "4"
        },
        {
          'points': "101,149,24,150,24,204,76,205,76,220,101,221",
          'room_no': "5"
        },
        {
          'points': "24,128,103,129,103,56,76,57,76,73,24,72",
          'room_no': "6"
        },
        {
          'points': "104,57,129,57,129,73,158,73,158,128,103,129",
          'room_no': "7"
        },
        {
          'points': "380,166,373,236,340,232,341,226,321,223,327,161",
          'room_no': "8"
        },
        {
          'points': "274,155,325,160,319,230,288,227,289,222,268,219",
          'room_no': "9"
        },
        {
          'points': "388,73,382,143,329,138,335,76,356,78,356,70",
          'room_no': "10"
        },
        {
          'points': "421,145,492,144,492,73,457,74,457,92,420,93",
          'room_no': "11"
        },
        {
          'points': "492,74,574,75,575,115,526,116,526,144,492,143",
          'room_no': "12"
        },
        {
          'points': "526,116,576,116,580,195,525,195",
          'room_no': "13"
        },
        {
          'points': "581,232,529,233,529,236,492,236,492,166,526,166,526,195,580,195",
          'room_no': "14"
        },
        {
          'points': "445,167,491,166,491,237,444,236",
          'room_no': "15"
        }
      ]
    },
    1: {
      'name': '1. Emelet',
      'click-areas': [
        {
          'points': "261,70,303,74,305,67,336,71,327,141,221,130,230,59,262,62",
          'room_no': "16"
        },
        {
          'points': "221,153,327,161,320,234,287,230,289,223,248,219,247,227,216,223",
          'room_no': "17"
        },
        {
          'points': "101,150,24,151,24,205,76,206,76,221,101,222",
          'room_no': "19"
        },
        {
          'points': "183,152,183,206,130,206,130,222,103,222,103,151",
          'room_no': "18"
        },
        {
          'points': "24,130,103,131,103,58,76,59,76,75,24,74",
          'room_no': "20"
        },
        {
          'points': "104,58,129,58,129,74,184,75,184,131,103,130",
          'room_no': "21"
        },
        {
          'points': "380,168,373,238,340,234,341,228,321,225,327,163",
          'room_no': "22"
        },
        {
          'points': "388,75,382,145,329,140,335,78,356,80,356,72",
          'room_no': "23"
        },
        {
          'points': "421,147,492,146,492,75,457,76,457,94,420,95",
          'room_no': "24"
        },
        {
          'points': "492,76,574,77,575,117,526,118,526,146,492,145",
          'room_no': "25"
        },
        {
          'points': "526,118,576,118,580,197,525,197",
          'room_no': "26"
        },
        {
          'points': "581,234,529,235,529,238,492,238,492,168,526,168,526,197,580,197",
          'room_no': "27"
        },
        {
          'points': "490,168,493,238,457,239,456,220,420,219,419,168",
          'room_no': "28"
        }
      ]
    },
    2: {
      'name': '2. Emelet',
      'click-areas': [
        {
          'points': "261,70,303,74,305,67,336,71,327,141,221,130,230,59,262,62",
          'room_no': "29"
        },
        {
          'points': "221,153,327,161,320,234,287,230,289,223,248,219,247,227,216,223",
          'room_no': "30"
        },
        {
          'points': "101,150,24,151,24,205,76,206,76,221,101,222",
          'room_no': "32"
        },
        {
          'points': "183,152,183,206,130,206,130,222,103,222,103,151",
          'room_no': "31"
        },
        {
          'points': "24,130,103,131,103,58,76,59,76,75,24,74",
          'room_no': "33"
        },
        {
          'points': "104,58,129,58,129,74,184,75,184,131,103,130",
          'room_no': "34"
        },
        {
          'points': "380,168,373,238,340,234,341,228,321,225,327,163",
          'room_no': "35"
        },
        {
          'points': "388,75,382,145,329,140,335,78,356,80,356,72",
          'room_no': "36"
        },
        {
          'points': "421,147,492,146,492,75,457,76,457,94,420,95",
          'room_no': "37"
        },
        {
          'points': "492,76,574,77,575,117,526,118,526,146,492,145",
          'room_no': "38"
        },
        {
          'points': "526,118,576,118,580,197,525,197",
          'room_no': "39"
        },
        {
          'points': "581,234,529,235,529,238,492,238,492,168,526,168,526,197,580,197",
          'room_no': "40"
        },
        {
          'points': "490,168,493,238,457,239,456,220,420,219,419,168",
          'room_no': "41"
        }
      ]
    },
    3: {
      'name': '3. Emelet',
      'click-areas': [
        {
          'points': "280,82,282,66,293,68,309,70,302,139,221,130,229,76,259,79",
          'room_no': "42"
        },
        {
          'points': "221,153,299,159,293,231,278,229,266,229,267,213,216,209,217,193",
          'room_no': "43"
        },
        {
          'points': "101,150,24,151,24,205,76,206,76,221,101,222",
          'room_no': "45"
        },
        {
          'points': "183,152,183,206,130,206,130,222,103,222,103,151",
          'room_no': "44"
        },
        {
          'points': "24,130,103,131,103,58,76,59,76,75,24,74",
          'room_no': "46"
        },
        {
          'points': "104,58,129,58,129,74,184,75,184,131,103,130",
          'room_no': "47"
        },
        {
          'points': "380,168,373,223,322,218,318,233,294,231,299,160",
          'room_no': "48"
        },
        {
          'points': "386,91,382,145,303,138,309,69,335,72,333,87",
          'room_no': "49"
        },
        {
          'points': "421,147,492,146,492,75,457,76,457,94,420,95",
          'room_no': "50"
        },
        {
          'points': "492,76,574,77,575,117,526,118,526,146,492,145",
          'room_no': "51"
        },
        {
          'points': "526,118,576,118,580,197,525,197",
          'room_no': "52"
        },
        {
          'points': "581,234,529,235,529,238,492,238,492,168,526,168,526,197,580,197",
          'room_no': "53"
        },
        {
          'points': "490,168,493,238,457,239,456,220,420,219,419,168",
          'room_no': "54"
        }
      ]
    },
    4: {
      'name': '4. Emelet',
      'click-areas': [
        {
          'points': "229,71,308,80,301,148,221,140",
          'room_no': "55"
        },
        {
          'points': "221,140,300,149,294,216,215,208",
          'room_no': "56"
        },
        {
          'points': "181,194,181,150,140,150,139,139,47,139,48,195",
          'room_no': "57"
        },
        {
          'points': "182,84,182,128,140,129,140,136,47,138,47,83",
          'room_no': "58"
        },
        {
          'points': "373,223,380,155,301,148,294,215",
          'room_no': "59"
        },
        {
          'points': "386,87,378,154,302,147,307,80",
          'room_no': "60"
        },
        {
          'points': "419,100,548,100,553,157,461,156,461,146,419,145",
          'room_no': "61"
        },
        {
          'points': "555,212,549,157,462,158,461,166,418,167,419,212",
          'room_no': "62"
        }
      ]
    }
  },
  'rooms': [
    {'code': 'AF1', 'room-count': '1 szoba', 'area-in': '35,13', 'area-out': '8,3'},
    {'code': 'AF2', 'room-count': '1 szoba', 'area-in': '35,08', 'area-out': '8,35'},
    {'code': 'AF3', 'room-count': '1 szoba', 'area-in': '35,24', 'area-out': '8,29'},
    {'code': 'AF4', 'room-count': '1+1 fél szoba', 'area-in': '46,91', 'area-out': '18,75'},
    {'code': 'AF5', 'room-count': '1+1 fél szoba', 'area-in': '46,96', 'area-out': '18,75'},
    {'code': 'AF6', 'room-count': '1+1 fél szoba', 'area-in': '46,94', 'area-out': '18,75'},
    {'code': 'AF7', 'room-count': '1 szoba', 'area-in': '23,31', 'area-out': '11,05'},
    {'code': 'BF1', 'room-count': '1 szoba', 'area-in': '35,34', 'area-out': '8,35'},
    {'code': 'BF2', 'room-count': '1 szoba', 'area-in': '35,23', 'area-out': '8,26'},
    {'code': 'BF3', 'room-count': '1 szoba', 'area-in': '35,34', 'area-out': '8,26'},
    {'code': 'BF4', 'room-count': '2 szoba', 'area-in': '42,96', 'area-out': '14,44'},
    {'code': 'BF5', 'room-count': '1+1 fél szoba', 'area-in': '42,96', 'area-out': '14,1'},
    {'code': 'BF6', 'room-count': '1+1 fél szoba', 'area-in': '39,43', 'area-out': '6,73'},
    {'code': 'BF7', 'room-count': '1+1 fél szoba', 'area-in': '43,53', 'area-out': '14,84'},
    {'code': 'BF8', 'room-count': '1 szoba', 'area-in': '32,77', 'area-out': '4,85'},

    {'code': 'A11', 'room-count': '2+1 fél szoba', 'area-in': '70,52', 'area-out': '16,65'},
    {'code': 'A12', 'room-count': '2+1 fél szoba', 'area-in': '70,77', 'area-out': '16,65'},
    {'code': 'A13', 'room-count': '1+1 fél szoba ', 'area-in': '46,91', 'area-out': '18,75'},
    {'code': 'A14', 'room-count': '1+1 fél szoba ', 'area-in': '46,94', 'area-out': '18,75'},
    {'code': 'A15', 'room-count': '1+1 fél szoba ', 'area-in': '46,94', 'area-out': '18,75'},
    {'code': 'A16', 'room-count': '1+1 fél szoba ', 'area-in': '46,92', 'area-out': '18,7'},
    {'code': 'B11', 'room-count': '1 szoba', 'area-in': '35,34', 'area-out': '8,35'},
    {'code': 'B12', 'room-count': '1 szoba', 'area-in': '35,23', 'area-out': '8,26'},
    {'code': 'B13', 'room-count': '2 szoba', 'area-in': '42,96', 'area-out': '14,48'},
    {'code': 'B14', 'room-count': '1+1 fél szoba ', 'area-in': '42,88', 'area-out': '14,1'},
    {'code': 'B15', 'room-count': '1+1 fél szoba ', 'area-in': '39,43', 'area-out': '6,73'},
    {'code': 'B16', 'room-count': '1+1 fél szoba ', 'area-in': '43,53', 'area-out': '14,84'},
    {'code': 'B17', 'room-count': '2 szoba', 'area-in': '32,77', 'area-out': '4,85'},

    {'code': 'A21', 'room-count': '2+1 fél szoba', 'area-in': '70,49', 'area-out': '16,85'},
    {'code': 'A22', 'room-count': '2+1 fél szoba', 'area-in': '70,74', 'area-out': '16,65'},
    {'code': 'A23', 'room-count': '1+1 fél szoba', 'area-in': '46,91', 'area-out': '18,75'},
    {'code': 'A24', 'room-count': '1+1 fél szoba', 'area-in': '46,94', 'area-out': '18,75'},
    {'code': 'A25', 'room-count': '1+1 fél szoba', 'area-in': '46,94', 'area-out': '18,75'},
    {'code': 'A26', 'room-count': '1+1 fél szoba', 'area-in': '46,92', 'area-out': '18,7'},
    {'code': 'B21', 'room-count': '1 szoba', 'area-in': '35,34', 'area-out': '8,35'},
    {'code': 'B22', 'room-count': '1 szoba', 'area-in': '35,23', 'area-out': '8,26'},
    {'code': 'B23', 'room-count': '2 szoba', 'area-in': '42,96', 'area-out': '14,48'},
    {'code': 'B24', 'room-count': '1+1 fél szoba', 'area-in': '42,88', 'area-out': '14,1'},
    {'code': 'B25', 'room-count': '1+1 fél szoba', 'area-in': '39,43', 'area-out': '6,73'},
    {'code': 'B26', 'room-count': '1+1 fél szoba', 'area-in': '43,53', 'area-out': '14,84'},
    {'code': 'B27', 'room-count': '2 szoba', 'area-in': '43,04', 'area-out': '13,61'},

    {'code': 'A31', 'room-count': '1+1 fél szoba', 'area-in': '46,92', 'area-out': '18,7'},
    {'code': 'A32', 'room-count': '1+1 fél szoba', 'area-in': '46,92', 'area-out': '18,7'},
    {'code': 'A33', 'room-count': '1+1 fél szoba', 'area-in': '46,91', 'area-out': '18,75'},
    {'code': 'A34', 'room-count': '1+1 fél szoba', 'area-in': '46,94', 'area-out': '18,75'},
    {'code': 'A35', 'room-count': '1+1 fél szoba', 'area-in': '46,94', 'area-out': '18,75'},
    {'code': 'A36', 'room-count': '1+1 fél szoba', 'area-in': '46,92', 'area-out': '18,7'},
    {'code': 'B31', 'room-count': '1+1 fél szoba', 'area-in': '46,87', 'area-out': '18,7'},
    {'code': 'B32', 'room-count': '1+1 fél szoba', 'area-in': '46,88', 'area-out': '18,7'},
    {'code': 'B33', 'room-count': '2 szoba', 'area-in': '42,96', 'area-out': '14,48'},
    {'code': 'B34', 'room-count': '1+1 fél szoba', 'area-in': '42,88', 'area-out': '14,1'},
    {'code': 'B35', 'room-count': '1+1 fél szoba', 'area-in': '39,43', 'area-out': '6,73'},
    {'code': 'B36', 'room-count': '1+1 fél szoba', 'area-in': '43,53', 'area-out': '14,84'},
    {'code': 'B37', 'room-count': '2 szoba', 'area-in': '43,04', 'area-out': '13,61'},

    {'code': 'A41', 'room-count': '2 szoba', 'area-in': '53,12', 'area-out': '17,33'},
    {'code': 'A42', 'room-count': '2 szoba', 'area-in': '53,12', 'area-out': '17,33'},
    {'code': 'A43', 'room-count': '2+1 fél szoba', 'area-in': '68,71', 'area-out': '52,35'},
    {'code': 'A44', 'room-count': '2+1 fél szoba', 'area-in': '68,71', 'area-out': '52,35'},
    {'code': 'B41', 'room-count': '2 szoba', 'area-in': '53,79', 'area-out': '17,34'},
    {'code': 'B42', 'room-count': '2 szoba', 'area-in': '53,79', 'area-out': '17,34'},
    {'code': 'B43', 'room-count': '2+1 fél szoba', 'area-in': '67,73', 'area-out': '53,53'},
    {'code': 'B44', 'room-count': '2+1 fél szoba', 'area-in': '69,58', 'area-out': '52,88'},
  ]
};

var portFloorDB = {
  'floors': {
    0: {
      'name': 'Földszint',
      'click-areas': [
        {
          'points': "35,125.9 88.4,125.9 88.4,264.5 14.1,264.5 14.1,215.4 34.6,215.4",
          'room_no': "1",
          'soldBoxTop': '47%','soldBoxLeft': '5%' 
        },
        {
          'points': "88.4,392.2 88.4,264.5 14.1,264.5 14.1,303.4 35,303.4 35,350.3 14.1,350.3 14.1,392.2",
          'room_no': "2",
          'soldBoxTop': '74%','soldBoxLeft': '6%'
        },
        {
          'points': "206,214 206,124.5 171.3,124.5 171.3,145.4 130.6,145.4 130.6,214",
          'room_no': "3",
          'soldBoxTop': '39%','soldBoxLeft': '24%'
        },
        {
          'points': "206,214 206,303.4 171.3,303.4 171.3,282.5 130.6,282.5 130.6,214",
          'room_no': "4",
          'soldBoxTop': '56%','soldBoxLeft': '24%'
        },
        {
          'points': "281.4,214 281.4,124.5 246.7,124.5 246.7,145.4 206,145.4 206,214",
          'room_no': "5",
          'soldBoxTop': '39%','soldBoxLeft': '37%'
        },
        {
          'points': "281.4,214 281.4,303.4 246.7,303.4 246.7,282.5 206,282.5 206,214",
          'room_no': "6",
          'soldBoxTop': '56%','soldBoxLeft': '37%'
        },
        {
          'points': "398.9,180.8 398.9,91.3 364.2,91.3 364.2,112.2 323.6,112.2 323.6,180.8",
          'room_no': "7",
          'soldBoxTop': '31%','soldBoxLeft': '56%'
        },
        {
          'points': "398.9,180.8 398.9,270.2 364.2,270.2 364.2,249.3 323.6,249.3 323.6,180.8",
          'room_no': "8",
          'soldBoxTop': '48%','soldBoxLeft': '56%'
        },
        {
          'points': "474.1,180.8 474.1,91.3 439.4,91.3 439.4,112.2 398.9,112.2 398.9,180.8",
          'room_no': "9",
          'soldBoxTop': '31%','soldBoxLeft': '69%'
        },
        {
          'points': "474.1,180.8 474.1,270.2 439.4,270.2 439.4,249.3 398.9,249.3 398.9,180.8",
          'room_no': "10",
          'soldBoxTop': '48%','soldBoxLeft': '69%'
        },
        {
          'points': "516.3,91.3 516.3,168.1 590.6,168.1 590.6,29.9 536.2,29.9 536.2,91.3",
          'room_no': "11",
          'soldBoxTop': '27%','soldBoxLeft': '89%'
        },
        {
          'points': "516.3,168.1 590.6,168.1 590.6,250 516.3,250",
          'room_no': "12",
          'soldBoxTop': '46%','soldBoxLeft': '89%'
        },
      ]
    },
    1: {
      'name': '1. Emelet',
      'click-areas': [
        {
          'points': "35,125.9 88.4,125.9 88.4,264.5 14.1,264.5 14.1,215.4 34.6,215.4",
          'room_no': "13",
          'soldBoxTop': '47%','soldBoxLeft': '5%' 
        },
        {
          'points': "88.4,392.2 88.4,264.5 14.1,264.5 14.1,303.4 35,303.4 35,350.3 14.1,350.3 14.1,392.2",
          'room_no': "14",
          'soldBoxTop': '74%','soldBoxLeft': '6%'
        },
        {
          'points': "206,214 206,124.5 171.3,124.5 171.3,145.4 130.6,145.4 130.6,214",
          'room_no': "15",
          'soldBoxTop': '39%','soldBoxLeft': '24%'
        },
        {
          'points': "206,214 206,303.4 171.3,303.4 171.3,282.5 130.6,282.5 130.6,214",
          'room_no': "16",
          'soldBoxTop': '56%','soldBoxLeft': '24%'
        },
        {
          'points': "281.4,214 281.4,124.5 246.7,124.5 246.7,145.4 206,145.4 206,214",
          'room_no': "17",
          'soldBoxTop': '39%','soldBoxLeft': '37%'
        },
        {
          'points': "281.4,214 281.4,303.4 246.7,303.4 246.7,282.5 206,282.5 206,214",
          'room_no': "18",
          'soldBoxTop': '56%','soldBoxLeft': '37%'
        },
        {
          'points': "398.9,180.8 398.9,91.3 364.2,91.3 364.2,112.2 323.6,112.2 323.6,180.8",
          'room_no': "19",
          'soldBoxTop': '31%','soldBoxLeft': '56%'
        },
        {
          'points': "398.9,180.8 398.9,270.2 364.2,270.2 364.2,249.3 323.6,249.3 323.6,180.8",
          'room_no': "20",
          'soldBoxTop': '48%','soldBoxLeft': '56%'
        },
        {
          'points': "474.1,180.8 474.1,91.3 439.4,91.3 439.4,112.2 398.9,112.2 398.9,180.8",
          'room_no': "21",
          'soldBoxTop': '31%','soldBoxLeft': '69%'
        },
        {
          'points': "474.1,180.8 474.1,270.2 439.4,270.2 439.4,249.3 398.9,249.3 398.9,180.8",
          'room_no': "22",
          'soldBoxTop': '48%','soldBoxLeft': '69%'
        },
        {
          'points': "516.3,91.3 516.3,168.1 590.6,168.1 590.6,29.9 536.2,29.9 536.2,91.3",
          'room_no': "23",
          'soldBoxTop': '27%','soldBoxLeft': '89%'
        },
        {
          'points': "516.3,168.1 590.6,168.1 590.6,250 516.3,250",
          'room_no': "24",
          'soldBoxTop': '46%','soldBoxLeft': '89%'
        }
      ]
    },
    2: {
      'name': '2. Emelet',
      'click-areas': [
        {
          'points': "35,125.9 88.4,125.9 88.4,264.5 14.1,264.5 14.1,215.4 34.6,215.4",
          'room_no': "25",
          'soldBoxTop': '47%','soldBoxLeft': '5%' 
        },
        {
          'points': "88.4,392.2 88.4,264.5 14.1,264.5 14.1,303.4 35,303.4 35,350.3 14.1,350.3 14.1,392.2",
          'room_no': "26",
          'soldBoxTop': '74%','soldBoxLeft': '6%'
        },
        {
          'points': "206,214 206,124.5 171.3,124.5 171.3,145.4 130.6,145.4 130.6,214",
          'room_no': "27",
          'soldBoxTop': '39%','soldBoxLeft': '24%'
        },
        {
          'points': "206,214 206,303.4 171.3,303.4 171.3,282.5 130.6,282.5 130.6,214",
          'room_no': "28",
          'soldBoxTop': '56%','soldBoxLeft': '24%'
        },
        {
          'points': "281.4,214 281.4,124.5 246.7,124.5 246.7,145.4 206,145.4 206,214",
          'room_no': "29",
          'soldBoxTop': '39%','soldBoxLeft': '37%'
        },
        {
          'points': "281.4,214 281.4,303.4 246.7,303.4 246.7,282.5 206,282.5 206,214",
          'room_no': "30",
          'soldBoxTop': '56%','soldBoxLeft': '37%'
        },
        {
          'points': "398.9,180.8 398.9,91.3 364.2,91.3 364.2,112.2 323.6,112.2 323.6,180.8",
          'room_no': "31",
          'soldBoxTop': '31%','soldBoxLeft': '56%'
        },
        {
          'points': "398.9,180.8 398.9,270.2 364.2,270.2 364.2,249.3 323.6,249.3 323.6,180.8",
          'room_no': "32",
          'soldBoxTop': '48%','soldBoxLeft': '56%'
        },
        {
          'points': "474.1,180.8 474.1,91.3 439.4,91.3 439.4,112.2 398.9,112.2 398.9,180.8",
          'room_no': "33",
          'soldBoxTop': '31%','soldBoxLeft': '69%'
        },
        {
          'points': "474.1,180.8 474.1,270.2 439.4,270.2 439.4,249.3 398.9,249.3 398.9,180.8",
          'room_no': "34",
          'soldBoxTop': '48%','soldBoxLeft': '69%'
        },
        {
          'points': "516.3,91.3 516.3,168.1 590.6,168.1 590.6,29.9 536.2,29.9 536.2,91.3",
          'room_no': "35",
          'soldBoxTop': '27%','soldBoxLeft': '89%'
        },
        {
          'points': "516.3,168.1 590.6,168.1 590.6,250 516.3,250",
          'room_no': "36",
          'soldBoxTop': '46%','soldBoxLeft': '89%'
        }
      ]    
    },
    3: {
      'name': '3. Emelet',
      'click-areas': [
        {
          'points': "35,125.9 88.4,125.9 88.4,264.5 14.1,264.5 14.1,215.4 34.6,215.4",
          'room_no': "37",
          'soldBoxTop': '47%','soldBoxLeft': '5%' 
        },
        {
          'points': "88.4,392.2 88.4,264.5 14.1,264.5 14.1,303.4 35,303.4 35,350.3 14.1,350.3 14.1,392.2",
          'room_no': "38",
          'soldBoxTop': '74%','soldBoxLeft': '6%'
        },
        {
          'points': "206,214 206,124.5 171.3,124.5 171.3,145.4 130.6,145.4 130.6,214",
          'room_no': "39",
          'soldBoxTop': '39%','soldBoxLeft': '24%'
        },
        {
          'points': "206,214 206,303.4 171.3,303.4 171.3,282.5 130.6,282.5 130.6,214",
          'room_no': "40",
          'soldBoxTop': '56%','soldBoxLeft': '24%'
        },
        {
          'points': "281.4,214 281.4,124.5 246.7,124.5 246.7,145.4 206,145.4 206,214",
          'room_no': "41",
          'soldBoxTop': '39%','soldBoxLeft': '37%'
        },
        {
          'points': "281.4,214 281.4,303.4 246.7,303.4 246.7,282.5 206,282.5 206,214",
          'room_no': "42",
          'soldBoxTop': '56%','soldBoxLeft': '37%'
        },
        {
          'points': "398.9,180.8 398.9,91.3 364.2,91.3 364.2,112.2 323.6,112.2 323.6,180.8",
          'room_no': "43",
          'soldBoxTop': '31%','soldBoxLeft': '56%'
        },
        {
          'points': "398.9,180.8 398.9,270.2 364.2,270.2 364.2,249.3 323.6,249.3 323.6,180.8",
          'room_no': "44",
          'soldBoxTop': '48%','soldBoxLeft': '56%'
        },
        {
          'points': "474.1,180.8 474.1,91.3 439.4,91.3 439.4,112.2 398.9,112.2 398.9,180.8",
          'room_no': "45",
          'soldBoxTop': '31%','soldBoxLeft': '69%'
        },
        {
          'points': "474.1,180.8 474.1,270.2 439.4,270.2 439.4,249.3 398.9,249.3 398.9,180.8",
          'room_no': "46",
          'soldBoxTop': '48%','soldBoxLeft': '69%'
        },
        {
          'points': "516.3,91.3 516.3,168.1 590.6,168.1 590.6,29.9 536.2,29.9 536.2,91.3",
          'room_no': "47",
          'soldBoxTop': '27%','soldBoxLeft': '89%'
        },
        {
          'points': "516.3,168.1 590.6,168.1 590.6,250 516.3,250",
          'room_no': "48",
          'soldBoxTop': '46%','soldBoxLeft': '89%'
        }
      ] 
    },
    4: {
      'name': '4. Emelet',
      'click-areas': [
        {
          'points': "46.6,302.9 131.7,302.9 131.7,269.6 101.1,269.6 101.1,220.8 88.8,220.8 88.8,158.3 46.6,158.3",
          'room_no': "49",
          'soldBoxTop': '55%','soldBoxLeft': '9%'
        },
        {
          'points': "118.3,269.6 206,269.6 206,158.3 131.7,158.3 131.7,220.3 118.3,220.4",
          'room_no': "50",
          'soldBoxTop': '47%','soldBoxLeft': '24%'
        },
        {
          'points': "206,269.6 324.9,269.6 324.9,237 294.2,237 294.2,187.8 280.9,187.8 280.9,158.3 206,158.3",
          'room_no': "51",
          'soldBoxTop': '47%','soldBoxLeft': '39%'
        },
        {
          'points': "312.1,237 312.1,188 324.9,188 324.9,125.6 399.4,125.6 399.4,238.1",
          'room_no': "52",
          'soldBoxTop': '40%','soldBoxLeft': '57%'
        },
        {
          'points': "399.4,238.1 474.1,238.1 474.1,204.2 487.8,204.2 487.8,143.2 474.4,143.2 474.4,125.6 399.4,125.6",
          'room_no': "53",
          'soldBoxTop': '40%','soldBoxLeft': '69%'
        },
        {
          'points': "474.1,238.1 474.1,204.2 505.1,204.2 505.1,144 518.8,144 518.8,91.6 556.3,91.6 556.3,238.1",
          'room_no': "54",
          'soldBoxTop': '45%','soldBoxLeft': '84%'
        }
      ]
    }
  },
  'rooms': [
    {code: 'F/01', 'area-in':'69.66', 'area-out':'18.43'},
    {code: 'F/02', 'area-in':'68.71', 'area-out':'8.94'},
    {code: 'F/03', 'area-in':'48.07', 'area-out':'8.7'},
    {code: 'F/04', 'area-in':'47.93', 'area-out':'7.54'},
    {code: 'F/05', 'area-in':'48.35', 'area-out':'7.54'},
    {code: 'F/06', 'area-in':'48.44', 'area-out':'7.54'},
    {code: 'F/07', 'area-in':'48.07', 'area-out':'8.7'},
    {code: 'F/08', 'area-in':'47.93', 'area-out':'7.54'},
    {code: 'F/09', 'area-in':'48.35', 'area-out':'7.54'},
    {code: 'F/10', 'area-in':'48.44', 'area-out':'7.54'},
    {code: 'F/11', 'area-in':'71.36', 'area-out':'12.76'},
    {code: 'F/12', 'area-in':'49.98', 'area-out':'14.33'},

    {code: 'I/13', 'area-in':'69.68', 'area-out':'18.44'},
    {code: 'I/14', 'area-in':'68.71', 'area-out':'8.94'},
    {code: 'I/15', 'area-in':'48.07', 'area-out':'8.7'},
    {code: 'I/16', 'area-in':'47.93', 'area-out':'7.54'},
    {code: 'I/17', 'area-in':'48.35', 'area-out':'7.54'},
    {code: 'I/18', 'area-in':'48.44', 'area-out':'7.54'},
    {code: 'I/19', 'area-in':'48.07', 'area-out':'8.7'},
    {code: 'I/20', 'area-in':'47.93', 'area-out':'7.54'},
    {code: 'I/21', 'area-in':'48.35', 'area-out':'7.54'},
    {code: 'I/22', 'area-in':'48.44', 'area-out':'7.54'},
    {code: 'I/23', 'area-in':'71.37', 'area-out':'12.76'},
    {code: 'I/24', 'area-in':'49.98', 'area-out':'14.33'},

    {code: 'II/25', 'area-in':'69.66', 'area-out':'18.43'},
    {code: 'II/26', 'area-in':'68.71', 'area-out':'8.94'},
    {code: 'II/27', 'area-in':'48.07', 'area-out':'8.7'},
    {code: 'II/28', 'area-in':'47.93', 'area-out':'7.54'},
    {code: 'II/29', 'area-in':'48.35', 'area-out':'7.54'},
    {code: 'II/30', 'area-in':'48.44', 'area-out':'7.54'},
    {code: 'II/31', 'area-in':'48.07', 'area-out':'8.7'},
    {code: 'II/32', 'area-in':'47.93', 'area-out':'7.54'},
    {code: 'II/33', 'area-in':'48.35', 'area-out':'7.54'},
    {code: 'II/34', 'area-in':'48.44', 'area-out':'7.54'},
    {code: 'II/35', 'area-in':'71.37', 'area-out':'12.76'},
    {code: 'II/36', 'area-in':'49.98', 'area-out':'14.33'},

    {code: 'III/37', 'area-in':'69.66', 'area-out':'18.43'},
    {code: 'III/38', 'area-in':'68.71', 'area-out':'8.94'},
    {code: 'III/39', 'area-in':'48.07', 'area-out':'8.7'},
    {code: 'III/40', 'area-in':'47.93', 'area-out':'7.54'},
    {code: 'III/41', 'area-in':'48.35', 'area-out':'7.54'},
    {code: 'III/42', 'area-in':'48.44', 'area-out':'7.54'},
    {code: 'III/43', 'area-in':'48.07', 'area-out':'8.7'},
    {code: 'III/44', 'area-in':'47.93', 'area-out':'7.54'},
    {code: 'III/45', 'area-in':'48.35', 'area-out':'7.54'},
    {code: 'III/46', 'area-in':'48.44', 'area-out':'7.54'},
    {code: 'III/47', 'area-in':'71.37', 'area-out':'12.76'},
    {code: 'III/48', 'area-in':'49.98', 'area-out':'14.33'},

    {code: 'IV/49', 'area-in':'65.04', 'area-out':'127.69'},
    {code: 'IV/50', 'area-in':'76.07', 'area-out':'46.19'},
    {code: 'IV/51', 'area-in':'89.45', 'area-out':'46.29'},
    {code: 'IV/52', 'area-in':'76.07', 'area-out':'46.19'},
    {code: 'IV/53', 'area-in':'78.62', 'area-out':'46.19'},
    {code: 'IV/54', 'area-in':'64.99', 'area-out':'109.07'}
  ]
};

var currentFloor = null,
    lightbox;

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        /*var yahooForecast;
        $.getJSON('https://query.yahooapis.com/v1/public/yql?q=select%20*%20from%20weather.forecast%20where%20woeid%3D%20814934%20and%20u%3D%22c%22&format=json&env=store%3A%2F%2Fdatatables.org%2Falltableswithkeys&callback=',function(json) {
          yahooForecast = json.query.results.channel.item;
          console.log(yahooForecast);
          for (i = 0; i < 2; i++) {
            date = new Date(yahooForecast[i].date);
            $('#banner_holder_pict').append($('<div/>', {class: 'ybox-'+i}).append(
              $('<div/>', {class: 'ytemp', text: yahooForecast[i].high}),
              $('<div/>', {class: 'yicon', text: yahooToClimacon[yahooForecast[i].code]}),
              $('<div/>', {class: 'ydate', text: date.getFullYear()+'. '+monthNames[date.getMonth()]+'. '+date.getDay()+'.'})
            ));
          }
          });*/
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        jQuery(".slider","#offers-list").slick({
          dots: false,
          infinite: false,
          speed: 500,
          slidesToShow: 4,
          slidesToScroll: 1,
          appendArrows: $('#offers-list-control'),
          prevArrow: '<button type="button" class="slick-prev">&lt;</button>',
          nextArrow: '<button type="button" class="slick-next">&gt;</button>',
          responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 3
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                centerMode: true
              }
            }
          ]
        });
      },
      finalize: function() {

        // JavaScript to be fired on the home page, after the init JS
      }
    },

    'page_template_page_villa_plan': {
      init: function() {

        var getFloor = function(floorNo, img){
          var floor = villaFloorDB.floors[floorNo], ret = [jQuery('<h2>'+floor.name+'</h2>'), jQuery('<svg class="floor-img" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 600 350" width="600"><image></image></svg>')];
          ret[1].find('image').attr({'xlink:href':img.attr('src'), 'width':600, 'height':350});
          jQuery.each(floor['click-areas'], function(index, value){
            jQuery('<polygon/>').attr({'points':value.points, 'class':'room', 'data-room_no':value.room_no, 'style': 'fill:transparent;stroke:transparent;'}).appendTo(ret[1]);
          });
          ret[1].html(ret[1].html());
          return ret;
        };

        var getRoom = function(roomNo){
          var roomDB = villaFloorDB.rooms[roomNo-1];
          return jQuery('<div/>', {'class':'room-info', 'data-img':roomDB.code.toLowerCase()}).append([
            jQuery('<div/>', {'class':'code'}).html('Kód:<br/>' + roomDB.code),
            jQuery('<div/>', {'class':'desc'}).html([
              jQuery('<div/>', {'class':'area-in'}).html('<span>Lakásterület:</span>'+roomDB['area-in']),
              jQuery('<div/>', {'class':'area-out'}).html('<span>Erkélyterület:</span>'+roomDB['area-out']),
              jQuery('<div/>', {'class':'room-count'}).html('<span>Szobaszám:</span>'+roomDB['room-count'])
            ]),
          ]);
        };

        jQuery(".floor", "#villa-plan").on('click', function(e){
          e.preventDefault();
          var floorNo = jQuery(this).data('floor');
          if(currentFloor !== floorNo) {
            currentFloor = floorNo;
            jQuery(this).parent().find('.floor').attr('class','floor');
            jQuery(this).attr('class','floor selected');
            jQuery('.floor-img-holder', '#villa-plan').remove();
            jQuery('#villa-plan').append('<div class="preloader"><div>Betöltés ...</div></div>');

            var imgSrc = $imgPrefix+'emelet'+floorNo+'.jpg',
                img, floorHTML;

            img = jQuery('<img/>')
                .attr("src",imgSrc+ "?" + new Date().getTime());
            jQuery(img).load(function() {
              jQuery('.preloader', '#villa-plan').remove();
              floorHTML = getFloor(floorNo, img);
              jQuery('<div/>', {'class':'floor-img-holder'})
                  .append([floorHTML[0], jQuery('<div/>', {'class':'svg-holder'}).html(floorHTML[1])])
                  .appendTo(jQuery('#villa-plan'));
            });
          }
          else {
            return false;
          }
        });

        jQuery("#villa-plan").on('mouseenter', ".room", function(e){
          jQuery('.room-info', '#villa-plan').remove();
          jQuery('.floor-img-holder > .svg-holder', '#villa-plan').append(getRoom(jQuery(this).data('room_no')));
        });
        jQuery("#villa-plan").on('click', ".room", function(e){
          lightbox($imgPrefix+'images/'+ jQuery('.room-info', '#villa-plan').data('img')+'.jpg');
        });

      },
      finalize: function() {
        lightbox = lity();
      }
    },


    'page_template_page_port_plan': {
      init: function() {

        var getFloor = function (floorNo, img) {
          var floor = portFloorDB.floors[floorNo], ret = [jQuery('<h2>' + floor.name + '</h2>'), jQuery('<svg class="floor-img" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 600 420" width="600"><image></image></svg>'), []];
          ret[1].find('image').attr({'xlink:href': img.attr('src'), 'width': 600, 'height': 420});
          jQuery.each(floor['click-areas'], function (index, value) {
            jQuery('<polygon/>').attr({
              'points': value.points,
              'class': ( (jQuery.inArray(value.room_no, soldRoom) >= 0)?'room sold':'room'),
              'data-room_no': value.room_no,
              'style': 'fill:transparent;stroke:transparent;'
            }).appendTo(ret[1]);
            if(jQuery.inArray(value.room_no, soldRoom) >= 0) {
              ret[2].push(jQuery('<div/>',{
                'class':'sold-box',
                'text' :'Eladva',
              }).css({'top':value.soldBoxTop, 'left':value.soldBoxLeft}));
            }
          });
          ret[1].html(ret[1].html());
          return ret;
        };

        var getRoom = function(roomNo){
          var roomDB = portFloorDB.rooms[roomNo-1],
              desc;
          if(roomDB.hotel) {
            return jQuery('<div/>', {'class': 'room-info', 'data-no': roomNo}).append(
                jQuery('<div/>', {'class': 'hotel'}).html('Hotel terület')
            );
          } else if(jQuery.inArray(roomNo.toString(), soldRoom) >= 0) {
              return jQuery('<div/>', {'class':'room-info', 'data-no':roomNo}).append(
                  jQuery('<div/>', {'class':'code'}).html('Kód:<br/>' + roomDB.code),
                  jQuery('<div/>', {'class':'desc'}).html([
                    jQuery('<div/>', {'class':'area-in'}).html('<span>Lakásterület:</span>'+roomDB['area-in']+' m2'),
                    jQuery('<div/>', {'class':'area-out'}).html('<span>Erkélyterület:</span>'+roomDB['area-out']+' m2'),
                    jQuery('<div/>', {'class':'room-count'}).html('<span>Teljes terület:</span>'+(parseFloat(roomDB['area-in'])+parseFloat(roomDB['area-out'])).toFixed(2)+' m2')
                  ]),
                  jQuery('<div/>', {'class':'sold'}).html('Eladva')
              );
            } else {
            return jQuery('<div/>', {'class':'room-info', 'data-no':roomNo}).append([
              jQuery('<div/>', {'class':'code'}).html('Kód:<br/>' + roomDB.code),
              jQuery('<div/>', {'class':'desc'}).html([
                jQuery('<div/>', {'class':'area-in'}).html('<span>Lakásterület:</span>'+roomDB['area-in']+' m2'),
                jQuery('<div/>', {'class':'area-out'}).html('<span>Erkélyterület:</span>'+roomDB['area-out']+' m2'),
                jQuery('<div/>', {'class':'room-count'}).html('<span>Teljes terület:</span>'+(parseFloat(roomDB['area-in'])+parseFloat(roomDB['area-out'])).toFixed(2)+' m2')
              ])
            ]);
          }
        };

        jQuery(".floor", "#port-plan").on('click', function (e) {
          e.preventDefault();
          var floorNo = jQuery(this).data('floor');
          if (currentFloor !== floorNo) {
            currentFloor = floorNo;
            jQuery(this).parent().find('.floor').attr('class', 'floor');
            jQuery(this).attr('class', 'floor selected');
            jQuery('.floor-img-holder', '#port-plan').remove();
            jQuery('#port-plan').append('<div class="preloader"><div>Betöltés ...</div></div>');

            var imgSrc = $imgPrefix + 'emelet' + floorNo + '.jpg',
                img, floorHTML;

            img = jQuery('<img/>')
                .attr("src", imgSrc + "?" + new Date().getTime());
            jQuery(img).load(function () {
              jQuery('.preloader', '#port-plan').remove();
              floorHTML = getFloor(floorNo, img);
              jQuery('<div/>', {'class': 'floor-img-holder'})
                  .append([floorHTML[0], jQuery('<div/>', {'class':'svg-holder'}).html(floorHTML[1]).append(floorHTML[2])])
                  .appendTo(jQuery('#port-plan'));
            });
          }
          else {
            return false;
          }
        });

        jQuery("#port-plan").on('mouseenter', ".room", function (e) {
          jQuery('.room-info', '#port-plan').remove();
          jQuery('.floor-img-holder > .svg-holder', '#port-plan').append(getRoom(jQuery(this).data('room_no')));
        });
        jQuery("#port-plan").on('click', ".room, .sold-box", function (e) {
          lightbox($imgPrefix + 'images/lakas_' + jQuery('.room-info', '#port-plan').data('no') + '.jpg');
        });
      },

      finalize: function() {
        lightbox = lity();
      }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

